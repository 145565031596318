@use "./variables" as *;

//Team

.teamMembers {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.teamText {
    margin: 0 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profilePhoto {
    padding: 100px 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
}

.buttonFilter {
    padding: 10px 10px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    background-color: #e7f1fd;
    border-radius: 15px;
}

.buttonFilter button {
    color: #2F2C4A;
    background: transparent;
    padding: 20px 40px;
    border: 1px transparent solid;
    margin: 10px 0;
}

.buttonFilter button:hover,
.buttonFilter button:focus-within {
    color: $white;
    background-color: $secondary;
}

.teamMembers h3, 
.teamMembers p {
    padding: 10px 0;
    text-align: center;
}

.profile {
    display: flex;
    justify-content: center;
    position: relative;
}

.profile .image {
    position: absolute;
    z-index: -10;
    align-content: center;
}

.profile .image {
    margin-top: 15px;
    border-radius: 50%;
}

.profileInfo {
    text-align: center;
}

.imageOne, .imageTwo, .imageThree, .imageFour {
    padding: 25px 35px;
    background: $white;
    z-index: -20;
    border: 1px solid rgba(243,243,243,0.7);
    border-radius: 15px;
    width: 260px;
}

.imageOne {
    border: 1px solid transparent;
    box-shadow: 0px 34px 68px 0px rgba(116, 115, 128, 0.10);
}

@media (max-width: 1225px) {
    .imageOne, .imageTwo, 
    .imageThree, .imageFour {
        margin: 20px 15px;
    }
}

@media (max-width: 580px) {
    .buttonFilter {
        flex-direction: column;
    }

    .teamText {
        margin: 0 auto;
    }

    .profilePhoto {
        padding: 50px 0;
    }

}