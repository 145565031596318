@use "./variables" as *;

//Awards
.acheivements {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    margin-bottom: 100px;
}

.cardTitleOne, .cardTitleTwo {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.cardTitleTwo {
    margin-bottom: 25px;
}

.membersContainer {
    display: flex;
}

.membersPhoto, .awardsPhoto {
    margin-left: -10px;
}

.membersPhoto img {
    margin-right: -35px;
}

.cardArrow {
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.color {
    color: $secondary;
}

.awardsContainer {
    display: flex;
    align-items: center;
}

.members p {
    margin: 10px 0 0 40px;
}

.ratings, .awards {
    width: 25%;
}

.acheivement {
    width: 30%;
    padding-right: 20px;
}

.ratings, .awards {
    border: 1px solid rgba($color: #8C8C8C, $alpha: 0.1);
    border-radius: 15px;
    background: $white;
    padding: 30px;
}

.ratings {
    box-shadow: 0px 26px 49px 0px rgba(146, 165, 184, 0.14);
}

h4 {
    color: $primary;
    font-weight: 500;
}

.awardsPhoto {
    margin-top: 11px;
}

.ratingText, .awardText {
    padding: 25px 0;
    font-size: 0.9rem;
    color: #8C8C8C;
}

.acheivementText h3,
.acheivementText h5,
.acheivementDescription {
    padding-bottom: 20px;
}

.discoverButton .generalButton {
    filter: drop-shadow(0px 10px 22px rgba(33, 123, 244, 0.26));
    margin-left: 0;
    display: flex;
    align-items: center;
}

.generalButton img,
.cardArrow img{
    padding-left: 10px;
}

.color:visited {
    color: $secondary;
}

// Acheivement Media Queries

@media (max-width: 1410px) {
    .acheivements {
        flex-direction: column;
        margin-bottom: 40px;
    }

    .ratings, .awards, .acheivement {
        width: 285px;
        margin: 10px auto;
        text-align: center;
    }

    .cardTitleOne, .cardTitleTwo, 
    .cardArrow {
        justify-content: center;
    }

    .discoverButton {
        display: flex;
        justify-content: center;
      }

      .members p, .awards p {
        font-size: 0.9rem;
      }
}
